@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.rankingChangeWrapper {
  @include font-primary-body-B5;
  align-items: center;
  display: flex;
  height: 28px;
  justify-content: center;
}

.iconContainer {
  margin-left: $spacing-2;
  margin-top: $spacing-2;

  &.hot {
    margin-top: $spacing-6;
  }
}

.new {
  @include font-primary-bold-SB6;
  background-color: $star;
  padding: $spacing-4;
  text-align: center;
  text-transform: uppercase;
}
