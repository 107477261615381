@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  margin-bottom: $spacing-4;
}

.recruitYear {
  @include font-primary-medium-S8;
  color: $abyss-50;
}

.status {
  @include font-primary-bold-SB8;
  color: $abyss;
  text-transform: uppercase;
}
