.iconTextCell {
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    height: fit-content;
    width: fit-content;
  }
}
