@import "~rivals/styles/mixins";
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/typography";
@import "~rivals/styles/colors";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.table {
  :global {
    .ant-table-content::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    .ant-table-tbody > .ant-table-row:hover > td {
      background: unset;
    }

    .ant-table-column-sorter {
      display: none; // hide so we can add custom sorting icons
    }
  }

  // Basic table styling

  :global {
    .ant-table-thead > tr {
      height: 44px;
    }

    // Custom styles for the header cells

    table > .ant-table-thead > tr > th {
      background-color: $bone;
      // TODO: https://griosf.atlassian.net/browse/RVLS-10723
      // Ant Design's default border color
      border-bottom: 1px solid #f0f0f0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      @include font-primary-bold-SB7;

      // Remove the borders between header cells

      &:before {
        content: "";
        display: none;
      }
    }
  }

  :global(.ant-table-tbody) {
    @include font-primary-body-B5;

    > tr {
      background-color: $bone;
      height: 60px;
      position: relative;

      &.darkRow {
        background-color: $sand;
      }

      > td {
        &:global(.ant-table-cell) {
          justify-content: center;
          padding: 0;
          position: unset;
          z-index: 2;
        }
      }
    }
  }

  &.emptyTable {
    :global {
      .ant-table-container {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    tbody > tr > td {
      background: $white;
    }
  }
}
