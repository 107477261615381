@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/colors";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.collegeCellWrapper {
  display: flex;
}

.labelWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-transform: uppercase;

  .text {
    margin-left: $spacing-12;
    order: 2;
  }

  .textInverted {
    margin-right: $spacing-12;
    order: 1;
  }
}
