@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.container {
  @include font-rivals-numbers-r2;
  color: $abyss;
  height: 100%;
  text-align: center;
  width: 100%;
}
