@import "~rivals/styles/colors";
@import "~rivals/styles/exports.module.scss";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

$ranking-cell-size: 24px;

.highlightCellWrapper {
  .highlightedCell {
    display: flex;
    justify-content: center;
    position: relative;

    .cellHighlight,
    .cellHighlightWide {
      border-radius: 4px;
      color: $trout;
      font-weight: bold;
      height: $ranking-cell-size;
      line-height: $ranking-cell-size;
      min-width: $ranking-cell-size;
      padding: 0 4px;
      text-align: center;
    }

    .invertedText {
      color: $white;
    }

    .iconContainer {
      left: 10px;
      position: absolute;
    }
  }
}
