@import "~rivals/styles/variables";

.tableWrapper {
  box-shadow: 0px 4px 14px 0px #0000001f;

  .table {
    :global {
      .ant-table-thead > tr > th {
        // Special case for the Athlete column

        &:nth-child(3) {
          padding-left: $spacing-40 + $spacing-16;
          text-align: left;
        }
      }
    }
  }
}
